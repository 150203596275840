import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/refindFacebook-1200x630.jpg';
import twitterImage from '../../assets/refindTwitter-600x314.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import css from './LandingPage.module.css';
import JoinRefindSection from './JoinRefindSection/JoinRefindSection';
import FinestFindsSection from './FinestFindsSection/FinestFindsSection';
import ListingsSection from './ListingsSection/ListingsSection';
import ListingsUnbrandedSection from './ListingsUnbrandedSection/ListingsUnbrandedSection';
import { useEffect } from 'react';
import Announcement from './Announcement/Announcement';
import PressSection from './PressSection/PressSection';
import ReWorkSection from './ReWorkSection/ReWorkSection';
import SectionNewsLetters from './SectionNewsLetters/SectionNewsLetters';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { GoogleTagManagerHandler } from '../../analytics/handlers';

const gtmHandler = new GoogleTagManagerHandler();

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  useEffect(() => {
    const previousPath = sessionStorage.getItem('previousNav');
    const canonicalPath = location.pathname;
    // console.log(canonicalPath, previousPath)
    gtmHandler.trackPageView(canonicalPath, previousPath);
  }, []);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const handleDirectSearch = query => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, query));
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer announcement={<Announcement intl={intl} />} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              intl={intl}
            />
          </div>
          <ul className={css.sections}>
            {/* <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionFilteredSearches />
              </div>
            </li> */}
            {/* <li className={css.section}>
              <FinestFindsSection />
            </li> */}
            <li className={css.section}>
              <ListingsSection />
            </li>
            <li className={css.section}>
              <ListingsUnbrandedSection handleDirectSearch={handleDirectSearch} />
            </li>
            <li className={css.section}>
              <SectionHowItWorks />
            </li>
            <li className={css.section}>
              <ReWorkSection />
            </li>
            <li className={css.section}>
              <PressSection />
            </li>
            <li className={css.section}>
              <JoinRefindSection />
            </li>
            <li className={css.section}>
              <SectionNewsLetters />
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
